@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

/* Import individual component styles */
@import './Components/Header/Header.scss';
@import './Components/Footer/Footer.scss';
@import './Components/Testimonials/Testimonials.scss';
@import './Components/Clients/Clients.scss';
@import './Pages/Home/Home.scss';
@import './Pages/Services/Services.scss';

/* Global styles */
body {
	font-family: 'Exo 2', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f4f4f9;
	color: #333;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.app-content {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: 0;
	padding: 0;

	header,
	footer {
		width: 100%; /* Ensure full width */
	}

	main {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2em;
		width: 100%;
	}
}

.container {
	padding: 2em;
	max-width: 1200px;
	margin: 0 auto;
	flex: 1;
	width: 100%;
	overflow-x: hidden;

	@media (max-width: 768px) {
		padding: 0; /* Remove padding on mobile devices */
	}
}

/* Utility classes */
.text-center {
	text-align: center;
}

.mt-2 {
	margin-top: 2em;
}

.mb-2 {
	margin-bottom: 2em;
}

.p-1 {
	padding: 1em;
}

.rounded {
	border-radius: 8px;
}

.box-shadow {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
