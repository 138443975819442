.container {
	padding: 2em;
	max-width: 1200px;
	margin: 0 auto;
	flex: 1;
	width: 100%;
	overflow-x: hidden;

	@media (max-width: 768px) {
		padding: 0; /* Remove padding on mobile devices */
	}
}

.services-page {
	text-align: center;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 1em; /* Default padding for larger screens */
	overflow-x: hidden;

	@media (max-width: 768px) {
		padding: 0; /* Remove padding on mobile devices */
	}

	h1 {
		color: #333;
		font-size: 2.5em;
		margin-bottom: 1em;
	}

	.filter-buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 0.5em;
		margin-bottom: 2em;

		button {
			background-color: #fff;
			color: #333;
			border: 1px solid #ddd;
			padding: 0.5em 1em;
			border-radius: 5px;
			cursor: pointer;
			transition: background-color 0.3s, color 0.3s;

			&.active,
			&:hover {
				background: linear-gradient(90deg, #ff4500, #ffd700);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				color: #fff;
			}
		}
	}

	.services-summary {
		display: flex;
		flex-wrap: wrap;
		gap: 1em;
		justify-content: center;

		.service-card {
			background-color: #fff;
			border: 1px solid #ddd;
			padding: 1em;
			border-radius: 8px;
			flex: 1 1 calc(33% - 1em);
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			text-align: center;
			transition: transform 0.3s;

			&:hover {
				transform: translateY(-10px);
			}

			h2 {
				background: linear-gradient(90deg, #ff4500, #ffd700);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				margin-top: 0;
			}

			ul {
				list-style: none;
				padding: 0;

				li {
					color: #666;
					margin: 0.5em 0;
				}
			}

			.price {
				margin-top: 1em;
				font-weight: bold;
				color: #333;
			}

			@media (max-width: 768px) {
				flex: 1 1 100%; /* Full width on mobile devices */
				padding: 0.5em; /* Reduce padding on mobile devices */
			}
		}
	}
}
