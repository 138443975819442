.footer {
	background-color: #333;
	padding: 1em 0;
	text-align: center;
	color: #777;

	@media (max-width: 768px) {
		margin-top: 2em;
	}

	p {
		margin: 0.5em 0;
	}

	.gradient-text {
		background: linear-gradient(90deg, #ff4500, #ffd700);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.heart {
		background: linear-gradient(90deg, #ff4500, #ffd700);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
