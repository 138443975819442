.services-preview {
	text-align: center;
	margin-bottom: 2em;

	h2 {
		color: #333;
		font-size: 2.5em;
		margin-bottom: 1em;
	}

	.services-summary {
		display: flex;
		flex-wrap: wrap;
		gap: 1em;
		justify-content: center;

		.service-card {
			background-color: #fff;
			border: 1px solid #ddd;
			padding: 1em;
			border-radius: 8px;
			flex: 1 1 calc(33% - 1em);
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			text-align: center;
			transition: transform 0.3s;

			&:hover {
				transform: translateY(-10px);
			}

			h2 {
				background: linear-gradient(90deg, #ff4500, #ffd700);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				margin-top: 0;
			}

			p {
				margin-top: 10px;
				color: #666;
			}
		}
	}

	.view-more-button {
		display: inline-block;
		margin-top: 20px;
		padding: 10px 20px;
		color: #fff;
		background: linear-gradient(90deg, #ff4500, #ffd700);
		background-size: 200% 100%;
		background-position: 0 0;
		text-decoration: none;
		border-radius: 5px;
		transition: background-position 0.5s;

		&:hover {
			background-position: 100% 0;
		}
	}
}
