.contact-container {
	padding: 1em 2em; /* Adjust padding to reduce vertical space */
	width: 100%; /* Ensure full width */
	max-width: 1000px; /* Use a higher max-width for larger screens */
	margin: 2em auto; /* Center the container and add vertical margin */
	background-color: #fff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	text-align: center;
	flex: 1; /* Ensure it takes up the available space */
	display: flex;
	flex-direction: column;
	justify-content: center; /* Center the content vertically */

	h2 {
		font-size: 2.5em;
		text-align: center;
		background: linear-gradient(90deg, #ff4500, #ffd700);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
		margin-bottom: 0.5em; /* Reduce bottom margin for less vertical space */
	}

	.form-group {
		margin-bottom: 1em;
		width: 80%; /* Increase width for form elements */
		max-width: 600px; /* Set a max-width for form elements */
		margin: 0.5em auto; /* Center form elements and add vertical margin */

		label {
			display: block;
			margin-bottom: 0.5em;
			color: #333;
		}

		input,
		textarea {
			width: 100%;
			padding: 0.75em;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 1em;
		}

		textarea {
			height: 150px;
			resize: vertical;
		}
	}

	.error {
		color: red;
		font-size: 0.875em;
		margin-top: 0.5em;
	}

	button {
		display: block;
		// width: 80%; /* Increase width for the button */
		padding: 0.75em;
		margin: 1em auto; /* Add vertical margin to separate from text area */
		background: linear-gradient(90deg, #ff4500, #ffd700);
		background-size: 200% 100%;
		background-position: 0 0;
		color: #fff;
		border: none;
		border-radius: 4px;
		font-size: 1.25em;
		cursor: pointer;
		transition: background-position 0.5s;

		&:hover {
			background-position: 100% 0;
		}

		&:disabled {
			background-color: #ccc;
			cursor: not-allowed;
		}
	}

	@media (max-width: 768px) {
		padding: 1em;
		width: 90%; /* Ensure form container is full width on mobile */
		max-width: 100%; /* Ensure form container is full width on mobile */
		box-shadow: none; /* Remove box shadow on mobile for better look */

		.form-group {
			max-width: 100%; /* Ensure form elements are full width on mobile */
		}
	}
}
