.home-container {
	width: 100%;
	padding: 2em;
	max-width: 1200px;
	margin: 0 auto;
	text-align: center;
	overflow-x: hidden;

	@media (max-width: 768px) {
		padding: 0; /* Remove padding on mobile devices */
	}

	.welcome-message {
		margin-bottom: 2em;

		h2 {
			font-size: 1.5em;
			font-weight: normal;
			background: linear-gradient(90deg, #ff4500, #ffd700);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		h1 {
			font-size: 2.5em;
			font-weight: bold;
			color: #333;
		}

		.intro-text {
			max-width: 60%;
			margin: 0 auto;
			color: #333;
			font-size: 1.25em;
			line-height: 1.5;

			@media (max-width: 768px) {
				max-width: 80%;
			}

			@media (max-width: 480px) {
				max-width: 100%;
			}
		}
	}

	.services-preview {
		display: flex;
		flex-wrap: wrap;
		gap: 1em;
		justify-content: center;

		.service-card {
			background-color: #fff;
			border: 1px solid #ddd;
			padding: 1em;
			border-radius: 8px;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			text-align: center;
			transition: transform 0.3s;
			flex: 1 1 calc(33% - 1em);

			&:hover {
				transform: translateY(-10px);
			}

			h2 {
				background: linear-gradient(90deg, #ff4500, #ffd700);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				margin-top: 0;
			}

			p {
				color: #666;
			}

			@media (max-width: 768px) {
				flex: 1 1 100%;
			}
		}
	}

	.contact-section {
		margin-top: 3em;
		padding: 2em;
		background-color: #f9f9f9;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		max-width: 80%;
		margin: 3em auto 0;

		@media (max-width: 768px) {
			padding: 0; /* Remove padding on mobile devices */
			max-width: 90%;
		}

		@media (max-width: 480px) {
			max-width: 100%;
		}

		h2 {
			font-size: 1.75em;
			color: #333;
			margin-bottom: 0.75em;
			padding-top: 0.75em;
		}

		p {
			color: #666;
			margin-bottom: 1em;
			font-size: 1em;
		}

		.contact-button {
			display: inline-block;
			padding: 0.75em 1.5em;
			margin: 1em;
			background: linear-gradient(90deg, #ff4500, #ffd700);
			background-size: 200% 100%;
			background-position: 0 0;
			color: #fff;
			text-decoration: none;
			font-size: 1em;
			font-weight: bold;
			border-radius: 4px;
			transition: background-position 0.5s;

			&:hover {
				background-position: 100% 0;
			}
		}
	}
}
