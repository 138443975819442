.header {
	background-color: #fff;
	padding: 1em 0;
	text-align: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	.header-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 90%;
		max-width: 1200px;
		margin: 0 auto;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;

		.logo-icon {
			width: 40px;
			height: 40px;
			color: rgb(251, 133, 0);
		}

		.logo-text {
			margin-left: 0.5em;
			font-size: 2.5em;
			font-weight: bold;

			@media (max-width: 768px) {
				font-size: 2em;
			}
		}
	}

	nav {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		a {
			color: #333;
			margin: 0 1em; /* Margin for spacing */
			text-decoration: none;
			font-weight: 600;
			font-size: 1.25em; /* Increased font size */
			transition: color 0.3s;

			&.active {
				background: linear-gradient(90deg, #ff4500, #ffd700);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			&:hover {
				background: linear-gradient(90deg, #ff4500, #ffd700);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			@media (max-width: 768px) {
				margin: 0.5em;
			}
		}
	}
}
