.clients {
	text-align: center;
	margin-top: 2em;

	h2 {
		color: #333;
		font-size: 2.5em; /* Increase the size */
	}

	.clients-logos {
		overflow: hidden;
		white-space: nowrap;
		position: relative;
		width: 100%;

		.logos-wrapper {
			display: inline-block;
			animation: scroll 30s linear infinite; /* Infinite scroll animation */
			white-space: nowrap;

			@media (max-width: 768px) {
				animation: scroll 15s linear infinite; /* Adjust speed for smaller screens */
			}

			a {
				display: inline-block;
				margin: 0 1em;

				img {
					height: 100px;
					width: auto;
					filter: grayscale(100%);
					transition: filter 0.3s;

					@media (max-width: 768px) {
						height: 75px;
					}

					&:hover {
						filter: none;
					}
				}
			}
		}
	}

	@keyframes scroll {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-100%);
		}
	}
}
