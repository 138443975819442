.carousel-wrapper {
	text-align: center;
	margin-top: 2em;

	h2 {
		color: #333;
		margin-bottom: 1em;
		font-size: 2.5em; /* Increase the size */
	}

	.carousel {
		margin-bottom: 2em;
	}

	.carousel .slider-wrapper {
		margin-bottom: 2em;
	}

	.carousel .control-dots {
		margin-top: 1em;
	}

	.carousel .control-dots .dot {
		background: #ddd;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin: 0 5px;
		transition: background 0.3s ease;
	}

	.carousel .control-dots .dot.selected {
		background: #ff4500;
	}

	.testimonial {
		background-color: #fff;
		border: 1px solid #ddd;
		padding: 1em;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		display: inline-block;
		margin: 1em;
		width: calc(100% - 4em);

		@media (min-width: 1024px) {
			width: calc(100% - 30em);
		}

		p {
			font-style: italic;
			color: #666;
		}

		h3 {
			margin: 0.5em 0 0;
			color: #333;
		}
	}
}
